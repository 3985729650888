<template>
  <layout-div>
    <h2 class="text-center mt-5 mb-3">Авторизация</h2>
    <div class="card">
        <div class="card-header">
                    <router-link to="/"
                        class="mx-1 btn btn-primary"
                        >Общие задачи
                    </router-link>
                    <router-link to="/login"
                        class="mx-1 btn btn-success"
                        >Войти
                    </router-link>
                    <router-link to="/register"
                        class="mx-1 btn btn-success"
                        >Зарегистрироваться
                    </router-link>
        </div>
        <div class="card-body">
            <form>
                <div class="form-group">
                    <label htmlFor="email">E-mail</label>
                    <input 
                        v-model="user.email"
                        type="email"
                        class="form-control"
                        id="email"
                        name="email"/>
                </div>
                <div class="form-group">
                    <label htmlFor="email">Пароль</label>
                    <input 
                        v-model="user.password"
                        type="password"
                        class="form-control"
                        id="password"
                        name="password"/>
                </div>
                <button 
                    @click="handleLogin()"
                    :disabled="isLogging"
                    type="button"
                    class="btn btn-outline-primary mt-3">
                    Войти
                </button>
            </form>
        </div>
    </div>
  </layout-div>
</template>
 
<script>
import axios from 'axios';
import LayoutDiv from '../LayoutDiv.vue';
import Swal from 'sweetalert2'
import isAuthed from '../../services/auth-check';
 
export default {
  name: 'UserLogin',
  components: {
    LayoutDiv,
  },
  data() {
    return {
      user: {
        email: '',
        password: '',
      },
      isLogging:false,
    };
  },
  created() {
    if (isAuthed()) {
      this.$router.push("/");
    }
  },
  setup() {
    return {isAuthed}
  },
  methods: {
    handleLogin() {
        this.isLogging = true
        axios.post('/user/login', this.user)
          .then(
            response => {
            if (response.data.result == 'success') {
              Swal.fire({
                  icon: 'success',
                  title: 'Успех!',
                  showConfirmButton: false,
                  timer: 1500
              }).then( res => {
                 console.log(res);
                 localStorage.setItem('user', JSON.stringify(response.data));
                 this.$router.push("/");
              });
            } else {
              Swal.fire({
                  icon: 'error',
                  title: 'Данные указаны не верно!',
                  showConfirmButton: false,
                  timer: 1500
              })
            }
            this.isLogging = false;
            return response;
          })
          .catch(error => {
            this.isLogging = false;
            Swal.fire({
                icon: 'error',
                title: 'Произошла ошибка!',
                showConfirmButton: false,
                timer: 1500
            })
            return error;
          });
    },
  },
};
</script>
