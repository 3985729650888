<template>
  <layout-div>
        <div class="container">
            <h2 class="text-center mt-5 mb-3">Список общих задач</h2>
            <div class="card">
                <div class="card-header">
                    <router-link to="/"
                        class="mx-1 btn btn-primary"
                        >Общие задачи
                    </router-link>
                    <router-link v-if="isAuthed()" to="/list"
                        class="mx-1 btn btn-primary"
                        >Мои задачи
                    </router-link>
                    <router-link v-if="isAuthed()" to="/create"
                        class="mx-1 btn btn-success"
                        >Новая задача
                    </router-link>
                    <router-link v-if="!isAuthed()" to="/login"
                        class="mx-1 btn btn-success"
                        >Войти
                    </router-link>
                    <router-link v-if="!isAuthed()" to="/register"
                        class="mx-1 btn btn-success"
                        >Зарегистрироваться
                    </router-link>
                    <button v-if="isAuthed()"
                        @click="handleLogout()"
                        className="btn btn-danger mx-1">
                        Выйти
                    </button>
                </div>
                <div class="card-body">
             
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Задача</th>
                                <th>Описание</th>
                                <th>Цвет</th>
                                <th>Действие</th>
                            </tr>
                        </thead>
                        <tbody>
                             
                            <tr v-for="task in tasks" :key="task.id">
                                <td>{{task.title}}</td>
                                <td>{{task.data}}</td>
                                <td>{{task.color}}</td>
                                <td>
                                    <router-link :to="`/view/${task.id}`" class="btn btn-info mx-1">Просмотр</router-link>
                                </td>
                            </tr>
                                 
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </layout-div>
</template>
 
<script>
import axios from 'axios';
import LayoutDiv from '../LayoutDiv.vue';
import doLogout from '../../services/auth-logout';
import isAuthed from '../../services/auth-check';
 
export default {
  name: 'TaskListCommon',
  components: {
    LayoutDiv,
  },
  data() {
    return {
      tasks:[]
    };
  },
  created() {
    this.fetchTaskList();
  },
  setup() {
    return {isAuthed}
  },
  methods: {
    handleLogout(){
      doLogout();
      location.reload();
    },
    fetchTaskList() {
      axios.get('/common_tasks')
        .then(response => {
            this.tasks = response.data.tasks;
            return response
        })
        .catch(error => {
          return error
        });
    }
  },
};
</script>
