<template>
   <layout-div>
        <h2 class="text-center mt-5 mb-3">Просмотр задачи</h2>
        <div class="card">
            <div class="card-header">
                    <router-link to="/"
                        class="mx-1 btn btn-primary"
                        >Общие задачи
                    </router-link>
                    <router-link v-if="isAuthed()" to="/list"
                        class="mx-1 btn btn-primary"
                        >Мои задачи
                    </router-link>
                    <router-link v-if="isAuthed()" to="/create"
                        class="mx-1 btn btn-success"
                        >Новая задача
                    </router-link>
                    <router-link v-if="!isAuthed()" to="/login"
                        class="mx-1 btn btn-success"
                        >Войти
                    </router-link>
                    <router-link v-if="!isAuthed()" to="/register"
                        class="mx-1 btn btn-success"
                        >Зарегистрироваться
                    </router-link>
                    <button v-if="isAuthed()"
                        @click="handleLogout()"
                        className="btn btn-danger mx-1">
                        Выйти
                    </button>
            </div>
            <div class="card-body">
                <b className="text-muted">Задача:</b>
                <p>{{task.title}}</p>
                <b className="text-muted">Описание:</b>
                <p>{{task.data}}</p>
                <b className="text-muted">Цвет:</b>
                <p>{{task.color}}</p>
                <b className="text-muted">Общая:</b>
                <p>
                  {{task.is_common ? 'Да' : 'Нет'}}
                </p>
            </div>
        </div>
   </layout-div>
</template>
 
<script>
import axios from 'axios';
import LayoutDiv from '../LayoutDiv.vue';
import Swal from 'sweetalert2'
import doLogout from '../../services/auth-logout';
import authHeader from '../../services/auth-header';
import isAuthed from '../../services/auth-check';
 
export default {
  name: 'TaskView',
  components: {
    LayoutDiv,
  },
  data() {
    return {
      task: {
        title: '',
        data: '',
        color: '',
        is_common: 0,
      },
      isSaving:false,
    };
  },
  created() {
    const id = this.$route.params.id;
    axios.get(`/tasks/${id}`, { headers: authHeader() })
    .then(response => {
        let taskInfo = response.data.task
        this.task.title = taskInfo.title
        this.task.data = taskInfo.data
        this.task.color = taskInfo.color
        this.task.is_common = taskInfo.is_common
        return response
    })
    .catch(error => {
        Swal.fire({
            icon: 'error',
            title: 'Произошла ошибка!',
            showConfirmButton: false,
            timer: 1500
        })
        return error
    })
  },
  setup() {
    return {isAuthed}
  },
  methods: {
    handleLogout(){
      doLogout();
      location.reload();
    },
  },
};
</script>
