<template>
  <layout-div>
        <div class="container">
            <h2 class="text-center mt-5 mb-3">Список моих задач</h2>
            <div class="card">
                <div class="card-header">
                    <router-link to="/"
                        class="mx-1 btn btn-primary"
                        >Общие задачи
                    </router-link>
                    <router-link v-if="isAuthed()" to="/list"
                        class="mx-1 btn btn-primary"
                        >Мои задачи
                    </router-link>
                    <router-link v-if="isAuthed()" to="/create"
                        class="mx-1 btn btn-success"
                        >Новая задача
                    </router-link>
                    <router-link v-if="!isAuthed()" to="/login"
                        class="mx-1 btn btn-success"
                        >Войти
                    </router-link>
                    <router-link v-if="!isAuthed()" to="/register"
                        class="mx-1 btn btn-success"
                        >Зарегистрироваться
                    </router-link>
                    <button v-if="isAuthed()"
                        @click="handleLogout()"
                        className="btn btn-danger mx-1">
                        Выйти
                    </button>
                </div>
                <div class="card-body">
             
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Задача</th>
                                <th>Описание</th>
                                <th>Цвет</th>
                                <th>Общая</th>
                                <th width="340px">Действие</th>
                            </tr>
                        </thead>
                        <tbody>
                             
                            <tr v-for="task in tasks" :key="task.id">
                                <td>{{task.title}}</td>
                                <td>{{task.data}}</td>
                                <td>{{task.color}}</td>
                                <td>{{task.is_common ? 'Да' : 'Нет'}}</td>
                                <td>
                                    <router-link :to="`/view/${task.id}`" class="btn btn-info mx-1">Просмотр</router-link>
                                    <router-link :to="`/edit/${task.id}`" class="btn btn-warning mx-1">Изменить</router-link>
                                    <button 
                                        @click="handleDelete(task.id)"
                                        className="btn btn-danger mx-1">
                                        Удалить
                                    </button>
                                </td>
                            </tr>
                                 
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </layout-div>
</template>
 
<script>
import axios from 'axios';
import LayoutDiv from '../LayoutDiv.vue';
import Swal from 'sweetalert2'
import authHeader from '../../services/auth-header';
import doLogout from '../../services/auth-logout';
import isAuthed from '../../services/auth-check';

export default {
  name: 'TaskList',
  components: {
    LayoutDiv,
  },
  data() {
    return {
      tasks:[]
    };
  },
  created() {
    if (!isAuthed()) {
      this.$router.push("/");
    }
    this.fetchTaskList();
  },
  setup() {
    return {isAuthed}
  },
  methods: {
    fetchTaskList() {
      axios.get('/tasks', { headers: authHeader() })
        .then(response => {
            this.tasks = response.data.tasks;
            return response
        })
        .catch(error => {
          return error
        });
    },
    handleLogout(){
      doLogout();
      location.reload();
    },
    handleDelete(id){
        Swal.fire({
            title: 'Вы уверены?',
            text: "Это действие не получится отменить!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Да, удалить!',
            cancelButtonText: 'Я передумал'
          }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`/tasks/${id}`, { headers: authHeader() })
                .then( response => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Задача успешно удалена!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    this.fetchTaskList();
                    return response
                })
                .catch(error => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Произошла ошибка!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    return error
                });
            }
          })
    }
  },
};
</script>
