import { createApp } from 'vue';
import App from './App.vue';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import { createRouter, createWebHistory } from 'vue-router';
import TaskList from './components/pages/TaskList';
import TaskListCommon from './components/pages/TaskListCommon';
import TaskCreate from './components/pages/TaskCreate';
import TaskEdit from './components/pages/TaskEdit';
import TaskView from './components/pages/TaskView';
import UserLogin from './components/pages/UserLogin';
import UserRegister from './components/pages/UserRegister';
  
axios.defaults.baseURL = process.env.VUE_APP_API_URL

/*
let user = JSON.parse(localStorage.getItem('user'));
if (user && user.token) {
  axios.interceptors.request.use(function (config) {
    config.headers['XAuth'] = user.token;
    return config;
  });
}
*/
  
const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', component: TaskListCommon },
    { path: '/list', component: TaskList },
    { path: '/create', component: TaskCreate },
    { path: '/edit/:id', component: TaskEdit },
    { path: '/view/:id', component: TaskView },
    { path: '/login', component: UserLogin },
    { path: '/register', component: UserRegister },
  ],
});
  
createApp(App).use(router).mount('#app');