<template>
  <layout-div>
    <h2 class="text-center mt-5 mb-3">Новая задача</h2>
    <div class="card">
        <div class="card-header">
                    <router-link to="/"
                        class="mx-1 btn btn-primary"
                        >Общие задачи
                    </router-link>
                    <router-link v-if="isAuthed()" to="/list"
                        class="mx-1 btn btn-primary"
                        >Мои задачи
                    </router-link>
                    <router-link v-if="isAuthed()" to="/create"
                        class="mx-1 btn btn-success"
                        >Новая задача
                    </router-link>
                    <router-link v-if="!isAuthed()" to="/login"
                        class="mx-1 btn btn-success"
                        >Войти
                    </router-link>
                    <router-link v-if="!isAuthed()" to="/register"
                        class="mx-1 btn btn-success"
                        >Зарегистрироваться
                    </router-link>
                    <button v-if="isAuthed()"
                        @click="handleLogout()"
                        className="btn btn-danger mx-1">
                        Выйти
                    </button>
        </div>
        <div class="card-body">
            <form>
                <div class="form-group">
                    <label htmlFor="title">Задача</label>
                    <input 
                        v-model="task.title"
                        type="text"
                        class="form-control"
                        id="title"
                        name="title"/>
                </div>
                <div class="form-group">
                    <label htmlFor="data">Описание</label>
                    <textarea 
                        v-model="task.data"
                        class="form-control"
                        id="data"
                        rows="3"
                        name="data"></textarea>
                </div>
                    <div class="form-group">
                        <label htmlFor="is_common">Общая</label><br>
                        <input type="radio"
                            v-model="task.is_common"
                            class="form-check-input"
                            id="is_common_true"
                            value="1"
                            name="is_common" />
                        <label for="is_common_true" class="form-check-label">Да</label><br>
                        <input type="radio"
                            v-model="task.is_common"
                            class="form-check-input"
                            id="is_common_false"
                            value="0"
                            name="is_common" />
                        <label for="is_common_false" class="form-check-label">Нет</label>
                    </div>
                <button 
                    @click="handleSave()"
                    :disabled="isSaving"
                    type="button"
                    class="btn btn-outline-primary mt-3">
                    Создать
                </button>
            </form>
        </div>
    </div>
  </layout-div>
</template>
 
<script>
import axios from 'axios';
import LayoutDiv from '../LayoutDiv.vue';
import Swal from 'sweetalert2'
import doLogout from '../../services/auth-logout';
import authHeader from '../../services/auth-header';
import isAuthed from '../../services/auth-check';
 
export default {
  name: 'TaskCreate',
  components: {
    LayoutDiv,
  },
  data() {
    return {
      task: {
        title: '',
        data: '',
        color: '',
        is_common: 0,
      },
      isSaving:false,
    };
  },
  created() {
    if (!isAuthed()) {
      this.$router.push("/");
    }
  },
  setup() {
    return {isAuthed}
  },
  methods: {
    handleLogout(){
      doLogout();
      location.reload();
    },
    handleSave() {
        this.isSaving = true
        axios.post('/tasks', this.task, { headers: authHeader() })
          .then(response => {
            Swal.fire({
                icon: 'success',
                title: 'Задача успешно добавлена!',
                showConfirmButton: false,
                timer: 1500
            })
            this.isSaving = false
            this.task.title = ""
            this.task.data = ""
            return response
          })
          .catch(error => {
            this.isSaving = false
            Swal.fire({
                icon: 'error',
                title: 'Произошла ошибка!',
                showConfirmButton: false,
                timer: 1500
            })
            return error
          });
    },
  },
};
</script>
